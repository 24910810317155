import React from "react";
import Social from "../Social";
import SocialTwo from "../SocialTwo";
import ReactTyped from "react-typed";

const SliderDarkAnimation = () => {
  return (
    //    HERO
    <div className="beny_tm_hero" id="home">
      <div className="background">
        <div
          className="image"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/slider/7.jpg"
            })`,
          }}
        ></div>
        <div className="overlay"></div>
      </div>
      {/* End bg */}

      <div className="go-to go-to-next">
        <a href="#about">
          <span></span>
        </a>
      </div>
      {/* End animated goto button */}

      <div className="container">
        <div className="content">
          <div className="content_inner">
            <h3 className="name" data-aos="fade-up" data-aos-duration="1200">
              quick. clean. smol.
            </h3>
            <h1
              className="job"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="50"
            >
              <span className="typer-toper">
                <ReactTyped
                  loop
                  typeSpeed={50}
                  backSpeed={20}
                  strings={["Design & Architecture", "Full-Stack Development", "General Snazziness"]}
                  smartBackspace
                  shuffle={false}
                  backDelay={1000}
                  fadeOut={false}
                  fadeOutDelay={100}
                  loopCount={0}
                  showCursor
                  cursorChar="🔥"
                />
              </span>
              <br /> <span>Smol Technologies LLC</span>
            </h1>
            <p
              className="text"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              Smol Technologies is dedicated to creating the best possible user experiences from start to finish. We hope you like hover animations.
            </p>
            <div
              className="mobile_social"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
              <Social />
            </div>
            {/* End .social */}

            <div
              className="beny_tm_button"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <a className="anchor" href="#about">
                <span className="wrapper">
                  <span className="first">a few smol details</span>
                  <span className="second">a few smol details</span>
                </span>
              </a>
            </div>
            {/* End beny_tm_button */}
          </div>
        </div>
      </div>
      {/* End .container */}

      {/* <div className="social" data-aos="fade-left" data-aos-duration="1200">
        <SocialTwo />
      </div> */}
      {/* End .social */}
    </div>
    //HERO
  );
};

export default SliderDarkAnimation;
